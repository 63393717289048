import { useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAuth } from '../context';
import { Button, InputField, FieldError } from '../../../components/Elements';
import { AuthError, LoginHeader } from '../components';
import { SEO } from '../../../components';

import { ReactComponent as Logo } from '../assets/ai_logo.svg';
import { ReactComponent as BackgroundIMG } from '../assets/background.svg';

type FormValues = {
  username: string;
  password: string;
};

export const Login = () => {
  const navigate = useRef(useNavigate());

  const {
    signIn,
    resetSignIn,
    loginState: { loginError, loginErrorMessage, attemptingLogin, isLoggedIn },
  } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (isLoggedIn) navigate.current('/auth-redirect');

    return () => resetSignIn();
  }, [isLoggedIn, resetSignIn]);

  const loginHandler: SubmitHandler<FormValues> = ({ username, password }) => {
    signIn(username, password);
  };

  return (
    <main className="flex flex-col min-h-screen bg-blue-gradient items-center">
      <SEO title='Login'/>
      <div className="container z-10 mx-auto flex flex-col items-center px-3">
        <LoginHeader />

        <form className="mt-10 w-full max-w-[334px]" onSubmit={handleSubmit(loginHandler)}>
          {loginError && <AuthError message={loginErrorMessage} variant="yellow" />}

          <InputField
            label="Username"
            variant="white"
            registration={register('username', { required: true })}
          />
          {errors.username?.type === 'required' && (
            <FieldError message="User name is required" variant="yellow" />
          )}

          <InputField
            className="mt-4"
            type="password"
            registration={register('password', {
              required: true,
            })}
            variant="white"
            label="Password"
          />
          {errors.password?.type === 'required' && (
            <FieldError message="Password is required" variant="yellow" />
          )}

          <div className="mt-12 flex text-lg">
            <p className="mr-2 font-light text-white">Forgot your password?</p>
            <Link className="font-bold text-white hover:underline" to="/reset-password">
              Reset password
            </Link>
          </div>

          <Button className="mt-11 w-full" type="submit" isLoading={attemptingLogin}>
            Log In
          </Button>
        </form>

        <div className="mt-12 flex text-lg">
          <p className="mr-2 font-light text-white">No account?</p>
          <Link className="font-bold text-white hover:underline" to="/signup">
            Create account
          </Link>
        </div>
      </div>

      <div className="mt-auto text-white pt-6 pb-2">
        {process.env.REACT_APP_VERSION} {process.env.REACT_APP_ENV !== 'prd' && process.env.REACT_APP_ENV}
      </div>
      <Logo />
      <p className='text-center text-xs text-white font-light pb-3'>Copyright &copy; {new Date().getFullYear()} by ETS. All rights reserved.</p>
      <BackgroundIMG className="fixed right-0 top-0 bottom-0 z-0 max-w-[500px]" />
    </main>
  );
};
