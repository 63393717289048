import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { StoreProvider } from './redux';
import { App } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { generateAWSConfig } from './configs/aws-exports';
import {initChartJS} from './configs/chartjs';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

// Configure AWS
const awsConfig = generateAWSConfig();
Amplify.configure(awsConfig);

// Init ChartJS
initChartJS();

// Buffer was removed from react-scripts v5.0 but is a dependency of the video recorder. TypeScript doesn't like when you add properties to the window object so we'll use @ts-expect-error here
// @ts-expect-error
window.Buffer = window.Buffer || require('buffer').Buffer;

// Clear cache to fix service worker amplify bug.
caches.keys().then(cacheNames => {
  cacheNames.forEach(cacheName => {
    caches.delete(cacheName);
  });
});

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <App />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
